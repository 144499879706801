<!-- 课程详情-报名中心 -->
<template>
  <div class="flex-col page">

    <div class="flex-col group_3" v-loading="loading" element-loading-text="数据正在加载中"
      element-loading-spinner="el-icon-loading">
      <div class="flex-row justify-between group_4">
        <div class="flex-row group_5">
          <span class="text_2">当前位置：报名中心</span>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16629786958662112914.png"
            class="image_6" />
          <span class="text_3">课程详情</span>
        </div>
        <div class="flex-row">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994929819924446.png"
            class="image_7" @click="gobackBtn" />
          <span class="text_4" @click="gobackBtn">返回</span>
        </div>
      </div>
      <div class="flex-col section_3">
        <div class="flex-row justify-between group_7">
          <div class="flex-col items-start space-y-23">
            <span class="text_5">{{detail.name}}</span>
            <span class="text_6">创建于{{detail.create_time}}</span>
          </div>
          <div class="flex-col items-center text-wrapper" @click="baomingBtn"><span class="text_7">立即报名</span></div>

        </div>
        <div class="flex-col items-end group_9">
          <div class="flex-col space-y-130 section_4">
            <div class="divider">
              <!---->
            </div>
            <div class="divider">
              <!---->
            </div>
          </div>
          <div class="flex-col section_5">
            <div class="flex-row group_10">
              <div class="flex-col group_11">
                <div class="flex-row space-x-15 group_12">
                  <div class="section_6 view_2">
                    <!---->
                  </div>
                  <span class="text_8">培训简介</span>
                </div>
                <div style="padding: 1rem;" v-html="detail.introduction"></div>
              </div>
              <div class="flex-col space-y-35 group_14">
                <div class="flex-col">
                  <div class="flex-row space-x-15 group_16">
                    <div class="section_6">
                      <!---->
                    </div>
                    <span class="text_23">基础信息</span>
                  </div>
                  <div class="flex-row group_17 view_5">
                    <span class="text_25">课程学时：</span>
                    <span class="text_27" v-if="detail.credit_hours">{{detail.credit_hours}}</span>
                    <span class="text_27" v-else>-</span>
                  </div>
                  <div class="flex-row space-x-46 group_17 view_6">
                    <span class="text_29">课程类型：</span>
                    <span class="text_31" v-if="detail.curriculum_cate_name">{{detail.curriculum_cate_name}}</span>
                    <span class="text_31" v-else>-</span>
                  </div>
                  <div class="flex-row space-x-47 group_17">
                    <span class="text_33">培训方式：</span>
                    <span class="text_34" v-if="detail.train_type_name">{{detail.train_type_name}}</span>
                    <span class="text_34" v-else>-</span>
                  </div>
                  <div class="flex-row group_17">
                    <span class="text_25">培训专业：</span>
                    <span class="text_27" v-if="detail.train_major_name">{{detail.train_major_name}}</span>
                    <span class="text_27" v-else>-</span>
                  </div>
                  <div class="flex-row group_17">
                    <span class="text_25">培训教师：</span>
                    <span class="text_27" v-if="detail.teacher">{{detail.teacher}}</span>
                    <span class="text_27" v-else>-</span>
                  </div>
                  <div class="flex-row space-x-33 group_17">
                    <span class="text_39">课程负责人：</span>
                    <span class="text_40" v-if="detail.person_in_charge">{{detail.person_in_charge}}</span>
                    <span class="text_40" v-else>-</span>
                  </div>
                  <div class="flex-row group_17 view_9">
                    <span class="text_41">收费标准(元)：</span>
                    <span class="text_43" v-if="detail.charging_standard">￥{{detail.charging_standard}}</span>
                    <span class="text_43" v-else>-</span>
                  </div>
                  <div class="flex-row space-x-18 group_17">
                    <span class="text_45">缴费截止日期：</span>
                    <span class="text_46" v-if="detail.end_time">{{detail.end_time}}</span>
                    <span class="text_46" v-else>-</span>
                  </div>
                </div>
                <div class="flex-col">
                  <div class="flex-row space-x-15 group_16">
                    <div class="section_6">
                      <!---->
                    </div>
                    <span class="text_23">报名要求</span>
                  </div>
                  <div class="flex-row space-x-19 group_24">
                    <span class="text_48">报名学历要求：</span>
                    <span class="text_49" v-if="detail.education_cate_txt">{{detail.education_cate_txt}}</span>
                  </div>
                  <div class="flex-row space-x-18 group_25">
                    <span class="text_50">报名企业要求：</span>
                    <span class="text_51">无要求</span>
                  </div>
                </div>
                <div class="flex-col">
                  <div class="flex-row space-x-15 group_16">
                    <div class="section_6">
                      <!---->
                    </div>
                    <span class="text_23">考试信息</span>
                  </div>
                  <div class="flex-row space-x-18 group_25">
                    <span class="text_53">是否考试：</span>
                    <span class="text_55" v-if="detail.is_examination == 1">是</span>
                    <span class="text_55" v-if="detail.is_examination == 0">否</span>
                  </div>
                  <div v-if="detail.is_examination == 1">
                    <div class="flex-row space-x-19 group_25">
                      <span class="text_53">是否补考：</span>
                      <span class="text_55" v-if="detail.is_retest == 1">是</span>
                      <span class="text_55" v-if="detail.is_retest == 0">否</span>
                    </div>
                    <div class="flex-row space-x-19 group_25">
                      <span class="text_41">补考费用(元)：</span>
                      <span class="text_43" v-if="detail.retest_price">￥{{detail.retest_price}}</span>
                      <span class="text_43" v-else>无</span>
                    </div>
                    <div class="flex-row space-x-19 group_25">
                      <span class="text_29">可补考次数：</span>
                      <span class="text_31" v-if="detail.retest_num == -1">不可补考</span>
                      <span class="text_31" v-else-if="detail.retest_num == 0">不限次数</span>
                      <span class="text_31" v-else>{{detail.retest_num}}</span>
                    </div>
                    <div class="flex-row space-x-19 group_25">
                      <span class="text_63">补考有效期：</span>
                      <span class="text_64" v-if="detail.reset_effective_time">{{detail.reset_effective_time}}个月</span>
                      <span class="text_64" v-else>-</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        loading: false,
        detail: [],
      };
    },
    created() {
      let that = this;
      that.getCurriculumDetail();
    },
    methods: {
      getCurriculumDetail() {
        let that = this;
        that.loading = true;
        let curriculum_id = this.$route.query.curriculum_id;
        let param = {
          curriculum_id
        };
        that.$request.getCurriculumDetail(param).then((res) => {
          if (res.code == 200) {
            that.detail = res.datas;
            that.loading = false;
          }
        });
      },
      //返回
      gobackBtn() {
        let that = this;
        that.$router.go(-1)
      },
      //报名
      baomingBtn() {
        let that = this;
        if (that.detail.registration_instructions) {
          that.$confirm(that.detail.registration_instructions, '报名通知', {
            confirmButtonText: '我已知悉',
            cancelButtonText: '返回',
            type: 'warning',
            center: true
          }).then(() => {
            that.$confirm('您确定要报名当前课程吗', '报名课程', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              center: true
            }).then(() => {

              that.toBaoming();
            }).catch(() => {
              /* this.$message({
                type: 'info',
                message: '已取消删除'
              }); */
            });
          }).catch(() => {
            /* this.$message({
              type: 'info',
              message: '已取消删除'
            }); */
          });
        } else {
          that.$confirm('您确定要报名当前课程吗', '报名课程', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true
          }).then(() => {
            that.toBaoming();
          }).catch(() => {
            /* this.$message({
				type: 'info',
				message: '已取消删除'
			  }); */
          });
        }

      },
      // 报名情况
      toBaoming() {
        let that = this;
        let param = {
          curriculum_id: that.detail.curriculum_id,
        };
        that.$request.getCurriculumBaoming(param).then((res) => {
          if (res.code == 200) {
            that.$myMessage.success(res.msg);
            setTimeout(function() {
              //报名成功，跳转到支付页面
              that.$router.push({
                'path': '/user/order/detail',
                query: {
                  ordersn: res.datas.ordersn
                }
              })
            }, 1000);
          }
        });
      },
    },
  };
</script>

<style scoped lang="css">
  .page {
    background-color: #f5f6fa;
    width: 89% !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 1.25rem 1.25rem 1.88rem;
  }

  .section_1 {
    padding: 0.63rem 1.25rem 0.63rem 3.13rem;
    background-color: #ffffff;
  }

  .space-x-12>*:not(:first-child) {
    margin-left: 0.75rem;
  }

  .group {
    margin: 0.13rem 0;
    overflow: hidden;
    width: 15.56rem;
    height: 2.88rem;
  }

  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }

  .text {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }

  .space-x-15>*:not(:first-child) {
    margin-left: 0.94rem;
  }

  .group_1 {
    overflow: hidden;
    width: 12.88rem;
    height: 3.13rem;
  }

  .space-x-5>*:not(:first-child) {
    margin-left: 0.31rem;
  }

  .group_2 {
    overflow: hidden;
    width: 5.63rem;
    height: 3.13rem;
  }

  .image_1 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 3.13rem;
  }

  .text_1 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 0.88rem;
  }

  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }

  .image_2 {
    align-self: center;
    width: 1.13rem;
    height: 1.13rem;
  }

  .group_3 {
    /* padding: 0 1.25rem 4.44rem 17.19rem; */
  }

  .group_4 {
    padding: 1.25rem 1.41rem 1.25rem 1.56rem;
  }

  .group_5 {
    margin: 0.19rem 0 0.13rem;
    padding: 0.19rem 0 0.13rem;
    overflow: hidden;
    width: 12.25rem;
    height: 1.25rem;
  }

  .text_2 {
    margin-bottom: 0.13rem;
    flex-shrink: 0;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .image_6 {
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }

  .text_3 {
    margin-bottom: 0.13rem;
    flex-shrink: 0;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .image_7 {
    flex-shrink: 0;
    width: 1.56rem;
    height: 1.56rem;
    cursor: pointer;
  }

  .text_4 {
    margin: 0.41rem 0 0.38rem 0.28rem;
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.78rem;
    cursor: pointer;
  }

  .section_3 {
    padding: 0 1.25rem 2.63rem 1.53rem;
    background-color: #ffffff;
  }

  .group_7 {
    padding: 1.53rem 0 1.81rem;
  }

  .space-y-23>*:not(:first-child) {
    margin-top: 1.44rem;
  }

  .text_5 {
    color: #3d3d3d;
    font-size: 1.38rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.31rem;
  }

  .text_6 {
    color: #666666;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper {
    margin-top: 0.66rem;
    padding: 0.94rem 0 1rem;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 6rem;
    height: 2.75rem;
    cursor: pointer;
  }

  .text_7 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .group_9 {
    position: relative;
  }

  .space-y-130>*:not(:first-child) {
    margin-top: 8.13rem;
  }

  .section_4 {
    padding: 19.38rem 0 14.19rem;
    background-color: #fafbfc;
    width: 50%;
  }

  .divider {
    background-color: #d8d8d8;
    height: 0.031rem;
  }

  .section_5 {
    padding: 0.84rem 0 1.41rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    border: solid 0.031rem #dcdfe6;
  }

  .group_10 {
    margin-right: 1.28rem;
  }

  .group_11 {
    flex-shrink: 0;
    overflow: hidden;
    width: 50%;
    height: 26.56rem;
  }

  .group_12 {
    align-self: flex-start;
    overflow: hidden;
    width: 5.63rem;
  }

  .section_6 {
    background-color: #0074ff;
    width: 0.19rem;
    height: 1.25rem;
  }

  .view_2 {
    flex-shrink: 0;
  }

  .text_8 {
    margin-bottom: 0.16rem;
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .group_13 {
    margin: 1.56rem 0.31rem 0 1.06rem;
    line-height: 1.25rem;
  }

  .text_9 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_10 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_11 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_12 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_13 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_14 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_15 {
    color: #e21313;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_16 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_17 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_18 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_19 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_20 {
    color: #e21313;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_21 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .text_22 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 1.25rem;
  }

  .space-y-35>*:not(:first-child) {
    margin-top: 2.19rem;
  }

  .group_14 {
    margin-left: 1.56rem;
    flex: 1 1 auto;
    width: 50%;
  }

  .group_16 {
    padding-bottom: 1.56rem;
  }

  .text_23 {
    margin-bottom: 0.16rem;
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .group_17 {
    margin-left: 1.13rem;
    margin-top: 0.63rem;
    align-self: flex-start;
    padding: 0.19rem 0 0.25rem;
    overflow: hidden;
    /* width: 7.69rem; */
  }

  .view_5 {
    margin-left: 1.13rem;
    margin-top: 0;
  }

  .text_25 {
    flex-shrink: 0;
    color: #7d7d89;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_27 {
    margin: 0.5rem 0 0.25rem 2.88rem;
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
  }

  .space-x-46>*:not(:first-child) {
    margin-left: 2.88rem;
  }

  .group_18 {
    padding: 0.19rem 0 0.25rem;
    overflow: hidden;
    width: 10.88rem;
  }

  .view_6 {
    margin-left: 1.13rem;
    margin-top: 0.63rem;
    align-self: flex-start;
  }

  .text_29 {
    flex-shrink: 0;
    color: #7d7d89;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_31 {
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-47>*:not(:first-child) {
    margin-left: 2.94rem;
  }

  .group_19 {
    margin-left: 1.13rem;
    margin-top: 0.63rem;
    padding: 0.19rem 0 0.25rem;
    align-self: flex-start;
    overflow: hidden;
    width: 9.13rem;
  }

  .text_33 {
    flex-shrink: 0;
    color: #7d7d89;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_34 {
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-33>*:not(:first-child) {
    margin-left: 2.06rem;
  }

  .group_20 {
    margin-top: 0.63rem;
    padding: 0.19rem 0 0.16rem;
    align-self: flex-start;
    overflow: hidden;
    width: 16.88rem;
    margin-left: 1.13rem;
  }

  .text_39 {
    flex-shrink: 0;
    color: #7d7d89;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_40 {
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.91rem;
  }

  .group_21 {
    padding: 0.19rem 0 0.16rem;
    overflow: hidden;
    width: 11rem;
  }

  .view_9 {
    margin-left: 1.13rem;
    margin-top: 0.63rem;
    align-self: flex-start;
  }

  .text_41 {
    flex-shrink: 0;
    color: #7d7d89;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.88rem;
  }

  .text_43 {
    margin: 0.094rem 0 0.16rem 1.5rem;
    flex-shrink: 0;
    color: #ff5f5a;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.66rem;
  }

  .space-x-18>*:not(:first-child) {
    margin-left: 1.13rem;
  }

  .group_22 {
    margin-left: 1.13rem;
    margin-top: 0.63rem;
    padding: 0.19rem 0 0.25rem;
    align-self: flex-start;
    overflow: hidden;
    width: 11.94rem;
  }

  .text_45 {
    flex-shrink: 0;
    color: #7d7d89;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_46 {
    margin-top: 0.16rem;
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.59rem;
  }

  .space-x-19>*:not(:first-child) {
    margin-left: 1.19rem;
  }

  .group_24 {
    margin-left: 1.13rem;
    padding: 0.19rem 0 0.22rem;
    overflow: hidden;
  }

  .text_48 {
    flex-shrink: 0;
    color: #7d7d89;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_49 {
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.84rem;
  }

  .group_25 {
    margin-left: 1.13rem;
    margin-top: 0.63rem;
    padding: 0.19rem 0 0.25rem;
    align-self: flex-start;
    overflow: hidden;
    width: 19rem;
  }

  .text_50 {
    flex-shrink: 0;
    color: #7d7d89;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_51 {
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-y-294>*:not(:first-child) {
    margin-top: 18.38rem;
  }

  .group_26 {
    margin-left: 1.88rem;
    margin-top: 0.19rem;
    flex-shrink: 0;
  }

  .image_8 {
    width: 0.94rem;
    height: 0.94rem;
  }

  .group_27 {
    margin-right: 1.28rem;
    margin-top: 1.25rem;
    align-self: flex-end;
    width: 33.13rem;
  }

  .view_12 {
    flex-shrink: 0;
  }

  .text_52 {
    margin-bottom: 0.16rem;
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .image_11 {
    margin: 0.13rem 0 0.19rem;
  }

  .group_29 {
    align-self: flex-end;
    padding: 0.19rem 0 0.25rem;
    overflow: hidden;
    width: 8.25rem;
  }

  .view_13 {
    margin-right: 25.03rem;
    margin-top: 1.56rem;
  }

  .text_53 {
    flex-shrink: 0;
    color: #7d7d89;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_55 {
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.78rem;
  }

  .view_14 {
    margin-right: 25.03rem;
    margin-top: 0.63rem;
  }

  .view_15 {
    margin-right: 22.28rem;
    margin-top: 0.63rem;
    align-self: flex-end;
  }

  .view_16 {
    margin-right: 22.41rem;
    margin-top: 0.63rem;
    align-self: flex-end;
  }

  .group_30 {
    margin-right: 23.16rem;
    margin-top: 0.63rem;
    padding: 0.19rem 0 0.25rem;
    align-self: flex-end;
    overflow: hidden;
    width: 10.13rem;
  }

  .text_63 {
    flex-shrink: 0;
    color: #7d7d89;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_64 {
    margin-right: 0.22rem;
    flex-shrink: 0;
    color: #2f3541;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
</style>
